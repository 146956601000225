<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      stripe
      :highlight-current-row="false"
      v-loading="config.loading"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column prop="contract" label="合同" width="200" sortable>
        <template slot-scope="scope">
          <router-link :to="{ name: 'heyueDetail', query: { id: scope.row.id, is_jieban: 1, contract_id: scope.row.contract_id } }">
            <a :class="scope.row.financial_confirm === false ? 'notFinancialConfirmRow' : 'isFinancialConfirmRow'">{{ scope.row.contract }}</a>
          </router-link>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="signing" label="签约" width="200"> </el-table-column> -->
      <el-table-column prop="second_party_project" label="乙方&工程" width="300"> </el-table-column>
      <el-table-column prop="added" label="添加" width="200"> </el-table-column>
      <el-table-column align="center" label="材料通知单审核" width="400">
        <template slot-scope="scope">
          <el-tag type="" v-if="scope.row.tzd_wuzishenhe">物资未审核</el-tag>
          <el-tag type="success" v-if="scope.row.tzd_caiwushenhe">财务未审核</el-tag>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20,
      multipleSelection: []
    }
  },
  props: {
    tableData: Array,
    config: Object,
    usertype: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.usertype.contract_del) {
          if (this.usertype.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:50px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:50px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    handleEdit(row) {
      this.$emit('edit', row)
    },
    handleDelete(row) {
      this.$emit('del', row)
    },

    changePage(page) {
      this.$emit('changePage', page)
    },
    handleSelectionChange(val) {
      // console.log('child Selected rows:', val)
      this.$emit('getSelectedRows', val)
    }
  }
}
</script>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
